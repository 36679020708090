<template>
  <div class="blog-page bg-sage-50">
    <!-- Hero Section con diseño más dinámico y colorido -->
    <div class="blog-hero relative overflow-hidden mb-12">
      <div class="absolute inset-0 bg-gradient-to-r from-primary-color to-primary-color/80 z-10"></div>
      <div class="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1536819114556-1e10f967fb61?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8MHx8fHx8fA%3D%3D&auto=format&fit=crop&w=1500&q=80')] bg-cover bg-center opacity-30"></div>
      <!-- Elementos decorativos con naranja -->
      <div class="absolute top-10 right-10 w-20 h-20 bg-accent-color rounded-full opacity-20 z-10"></div>
      <div class="absolute bottom-10 left-10 w-32 h-32 bg-accent-color rounded-full opacity-20 z-10"></div>
      
      <div class="container max-w-7xl mx-auto px-4 py-20 relative z-20">
        <h1 class="text-5xl md:text-6xl font-bold text-white mb-4 drop-shadow-md">
          Repro<span class="text-accent-color">Post</span>
        </h1>
        <p class="text-white text-lg md:text-xl max-w-2xl drop-shadow-md">
          Mantente informado sobre las últimas noticias, investigaciones y avances en el mundo del cannabis medicinal, legislación y cultura cannábica.
        </p>
        <!-- Botón eliminado -->
      </div>

    </div>
    
    <div class="container max-w-7xl mx-auto px-4 pb-16">
      <!-- Filtros y búsqueda con más naranja -->
      <div class="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
        <div class="flex flex-wrap gap-2">
          <button 
            v-for="category in categories" 
            :key="category"
            @click="selectCategory(category)"
            :class="[
              'px-4 py-2 rounded-full text-sm font-medium transition-all',
              selectedCategory === category 
                ? 'bg-accent-color text-white shadow-md font-bold' 
                : 'bg-white text-gray-700 hover:bg-gray-100 shadow-sm border border-gray-200'
            ]"
          >
            {{ category }}
          </button>
        </div>
        
        <div class="relative">
          <input 
            type="text" 
            placeholder="Buscar artículos..." 
            class="pl-10 pr-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-accent-color/50 w-full md:w-64 bg-white"
            v-model="searchQuery"
          >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
          </svg>
        </div>
      </div>

      <!-- Estado de carga -->
      <div v-if="loading" class="flex flex-col items-center justify-center py-16 bg-white rounded-lg shadow-sm">
        <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-accent-color mb-4"></div>
        <p class="text-gray-600">Cargando artículos...</p>
      </div>
      
      <!-- Mensaje de error -->
      <div v-else-if="error" class="bg-red-50 text-red-600 p-4 rounded-lg text-center shadow-sm">
        {{ error }}
      </div>
      
      <!-- Sin resultados -->
      <div v-else-if="filteredPosts.length === 0" class="bg-white p-8 rounded-lg text-center shadow-sm">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto text-accent-color mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <h3 class="text-lg font-medium text-gray-700 mb-2">No se encontraron artículos</h3>
        <p class="text-gray-500">Intenta con otra categoría o término de búsqueda</p>
      </div>
      
      <!-- Lista de posts con más elementos naranjas -->
      <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <router-link 
          v-for="post in filteredPosts" 
          :key="post.id" 
          :to="`/post/${post.id}`"
          class="blog-post bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-xl transition-all duration-300 flex flex-col h-full relative"
        >
          <!-- Esquina naranja decorativa -->
          <div class="absolute top-0 right-0 w-16 h-16 overflow-hidden z-10">
            <div class="absolute transform rotate-45 bg-accent-color text-white font-medium text-xs py-1 text-center w-24 top-3 right-[-24px]">
              {{ post.category[0] || 'Blog' }}
            </div>
          </div>
          
          <div class="aspect-w-16 aspect-h-9 relative">
            <img 
              :src="post.image || '/placeholder-image.jpg'" 
              :alt="post.title" 
              class="w-full h-full object-cover"
            >
            <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300"></div>
          </div>
          <div class="p-5 flex-grow flex flex-col">
            <div class="flex flex-wrap items-center mb-3 gap-1">
              <span v-for="(cat, index) in post.category.slice(1)" :key="index" class="inline-block bg-accent-color/10 text-accent-color text-xs font-medium px-2.5 py-1 rounded-full">
                {{ cat }}
              </span>
            </div>
            <h2 class="text-xl font-semibold text-gray-800 mb-2 line-clamp-2">{{ post.title }}</h2>
            <p class="text-gray-600 mb-4 line-clamp-3">{{ post.subtitle }}</p>
            <div class="mt-auto flex items-center text-sm text-gray-500">
              <span class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1 text-accent-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                {{ formatDate(post.date) }}
              </span>
              <span class="mx-2 text-accent-color">•</span>
              <span class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1 text-accent-color" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg>
                {{ post.author }}
              </span>
            </div>
            <!-- Botón "Leer más" con color naranja -->
            <div class="mt-4 text-accent-color font-medium text-sm flex items-center">
              Leer más
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </div>
          </div>
        </router-link>
      </div>
      
      <!-- Paginación con naranja -->
      <div v-if="filteredPosts.length > 0" class="flex justify-center mt-12">
        <button class="px-4 py-2 mx-1 rounded-md bg-white text-gray-700 hover:bg-gray-100 shadow-sm disabled:opacity-50 disabled:cursor-not-allowed border border-gray-200">
          Anterior
        </button>
        <button class="px-4 py-2 mx-1 rounded-md bg-accent-color text-white hover:bg-accent-color/90 shadow-sm">
          1
        </button>
        <button class="px-4 py-2 mx-1 rounded-md bg-white text-gray-700 hover:bg-gray-100 shadow-sm border border-gray-200">
          2
        </button>
        <button class="px-4 py-2 mx-1 rounded-md bg-white text-gray-700 hover:bg-gray-100 shadow-sm border border-gray-200">
          3
        </button>
        <button class="px-4 py-2 mx-1 rounded-md bg-white text-gray-700 hover:bg-gray-100 shadow-sm border border-gray-200">
          Siguiente
        </button>
      </div>
    </div>
    
    <!-- Suscripción al newsletter con diseño más dinámico -->
    <div class="bg-sage-100 py-12 relative overflow-hidden">
      <!-- Elementos decorativos -->
      <div class="absolute top-0 left-0 w-40 h-40 bg-accent-color rounded-full opacity-10"></div>
      <div class="absolute bottom-0 right-0 w-60 h-60 bg-accent-color rounded-full opacity-10"></div>
      
      <div class="container max-w-7xl mx-auto px-4 relative z-10">
        <div class="bg-white p-8 rounded-lg shadow-md max-w-3xl mx-auto text-center">
          <div class="inline-block bg-accent-color/10 text-accent-color text-sm font-medium px-3 py-1 rounded-full mb-4">
            ¡No te pierdas nada!
          </div>
          <h3 class="text-2xl font-bold text-gray-800 mb-4">Mantente informado</h3>
          <p class="text-gray-600 mb-6">Suscríbete a nuestro newsletter para recibir las últimas noticias y actualizaciones sobre cannabis medicinal.</p>
          
          <div class="flex flex-col sm:flex-row gap-3">
            <input type="email" placeholder="Tu correo electrónico" class="flex-grow px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-accent-color/50">
            <button class="bg-accent-color text-white px-6 py-3 rounded-md font-bold hover:bg-accent-color/90 transition-all transform hover:scale-105 shadow-md text-base">
              Suscribirme
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue'

export default {
  name: 'Blog',
  setup() {
    const posts = ref([])
    const loading = ref(true)
    const error = ref(null)
    const selectedCategory = ref('Todas')
    const categories = ref(['Todas'])
    const searchQuery = ref('')

    const filteredPosts = computed(() => {
      let result = posts.value

      // Filtrar por categoría
      if (selectedCategory.value !== 'Todas') {
        result = result.filter(post => post.category.includes(selectedCategory.value))
      }

      // Filtrar por búsqueda
      if (searchQuery.value.trim()) {
        const query = searchQuery.value.toLowerCase().trim()
        result = result.filter(post => 
          post.title.toLowerCase().includes(query) || 
          post.subtitle.toLowerCase().includes(query) ||
          post.author.toLowerCase().includes(query)
        )
      }

      return result
    })

    const fetchPosts = async () => {
      try {
        loading.value = true
        error.value = null

        const response = await fetch('https://backend-test.tango.reprocard.com/notion/post')
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        posts.value = data.results.map(page => ({
          id: page.id,
          title: page.properties.Title.title[0]?.plain_text || '',
          subtitle: page.properties['Sub-title']?.rich_text[0]?.plain_text || '',
          text: page.properties.Text?.rich_text[0]?.plain_text || '',
          image: page.properties['Imagen 1']?.files[0]?.file.url || '',
          date: page.properties['Fecha Publicación']?.date?.start || '',
          author: page.properties.Autor?.rich_text[0]?.plain_text || 'Anónimo',
          category: page.properties.Category?.multi_select.map(cat => cat.name) || ['Sin categoría']
        }))

        // Update categories
        const allCategories = posts.value.flatMap(post => post.category)
        const uniqueCategories = [...new Set(allCategories)]
        categories.value = ['Todas', ...uniqueCategories]

      } catch (err) {
        console.error('Error fetching posts:', err)
        error.value = 'No se pudieron cargar los artículos. Por favor, intenta más tarde.'
      } finally {
        loading.value = false
      }
    }

    const formatDate = (dateString) => {
      if (!dateString) return ''
      const date = new Date(dateString)
      const months = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
      return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
    }

    const selectCategory = (category) => {
      selectedCategory.value = category
    }

    onMounted(() => {
      fetchPosts()
    })

    return {
      posts,
      loading,
      error,
      selectedCategory,
      categories,
      searchQuery,
      filteredPosts,
      formatDate,
      selectCategory
    }
  }
}
</script>

<style scoped>
/* Aspect ratio container for images */
.aspect-w-16 {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
}

.aspect-w-16 > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Line clamp utilities */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Blog post card hover effects */
.blog-post {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post:hover {
  transform: translateY(-5px);
}

/* Variables de colores */
:root {
  --primary-color: #2F4F2F; /* Verde oscuro similar al de la imagen */
  --accent-color: #FF5722; /* Color naranja/acento más intenso */
}

/* Colores de fondo personalizados para el blog */
.bg-sage-50 {
  background-color: #f5f7f5; /* Un tono muy suave de verde/sage */
}

.bg-sage-100 {
  background-color: #e6ebe6; /* Un tono suave de verde/sage */
}

/* Clases de utilidad para colores de acento */
.text-accent-color {
  color: var(--accent-color);
}

/* Mejorar la visibilidad de los botones de categoría seleccionados */
.bg-accent-color {
  background-color: var(--accent-color);
}

.hover\:bg-accent-color\/90:hover {
  background-color: rgba(255, 87, 34, 0.9);
}

.bg-accent-color\/10 {
  background-color: rgba(255, 87, 34, 0.1);
}

/* Asegurar que el texto en botones sea visible */
button.bg-accent-color {
  color: white;
  font-weight: 600;
}

/* Sombra para el texto del hero para mejorar legibilidad */
.drop-shadow-md {
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.3));
}
</style>